import { acceptHMRUpdate, defineStore } from 'pinia'

// const algolia = useAlgolia()

export const useSearchStore = defineStore('searchStore', {

    state: () => ({
        isLoading: false,
        showGlobalSearchOverlay: false,
        searchQuery: '',
        // indices: ['prod_CUSTOMERS', 'prod_ORDERS', 'prod_EVENTS'],
        indices: ['all_customers', 'all_orders', 'all_events'],
        hitsPerPage: 4,
        searchRawResults: [],
        searchResultCustomers: [],
        searchResultOrders: [],
        searchResultEvents: [],
        forceCommandMode: false, // Command mode is if the user starts their search with '>' '?' or '!' 
        searchDisabled: false,
        queryTooShort: true,
        searchQueryPending: false,
        commandLinks: [
            {
                id: 1,
                name: 'Create a Customers',
                description: 'Add freeform text with basic formatting options.',
                route: { path: `/app/tenant-admin/customers` },
                color: 'bg-blue-500',
                icon: 'UserIcon',
            },
            {
                id: 2,
                name: 'Create an Order',
                description: 'Create a new order.',
                route: { path: `/app/tenant-admin/orders/create` },
                color: 'bg-primary-500',
                icon: 'PencilAltIcon',
            },
            {
                id: 3,
                name: 'Create an Coupon',
                description: 'Create a coupon / promo code.',
                route: { path: `/app/tenant-admin/coupons/create` },
                color: 'bg-primary-500',
                icon: 'CursorClickIcon',
            },
            {
                id: 4,
                name: 'View Transactions',
                description: 'View all transaction data.',
                route: { path: `/app/tenant-admin/transactions` },
                color: 'bg-primary-500',
                icon: 'ReceiptRefundIcon',
            },
            {
                id: 5,
                name: 'Set Up Scanners',
                description: 'Set up ticket scanners.',
                route: { path: `/app/tenant-admin/scanners` },
                color: 'bg-primary-500',
                icon: 'QrcodeIcon',
            },
            {
                id: 6,
                name: 'Get Help',
                description: 'Contact WeTix support team.',
                route: { path: `/app/tenant-admin/help` },
                color: 'bg-red-500',
                icon: 'QuestionMarkCircleIcon',
            },
        ],
        showCustomerPhoneNumbers: false,
    }),

    getters: {

        // Return config to use with algolia multi-index search
        allIndexesQueries: (state) => {
            return state.indices.map(index => {
                return {
                    indexName: index,
                    index: index,
                    tenant: useActiveTenantId(), // Get the active tenant ID from cookies.
                    query: state.searchQuery,
                    params: {
                        hitsPerPage: state.hitsPerPage
                    }
                }
            })
        },
        // Return the total results
        totalResults: (state) => {
            if (!state.searchRawResults) return 0
            if (!state.searchRawResults.length) return 0
            return state.searchRawResults.reduce((total, result) => {
                return total + (result?.results?.hits.length || 0)
            }, 0)
        },
        // Return the total customer results
        totalResultsCustomers: (state) => {
            console.log(`totalResultsCustomers`, state.searchRawResults)
            if (!state.searchRawResults || !state.searchRawResults.length) return 0
            if (!state.searchRawResults[0]) return 0
            return state.searchRawResults[0].results.hits.length || 0

        },
        // Return the total order results
        totalResultsOrders: (state) => {
            if (!state.searchRawResults || !state.searchRawResults.length) return 0
            if (!state.searchRawResults[1]) return 0
            return state.searchRawResults[1].results.hits.length || 0

        },
        // Return the total event results
        totalResultsEvents: (state) => {
            if (!state.searchRawResults || !state.searchRawResults.length) return 0
            if (!state.searchRawResults[2]) return 0
            return state.searchRawResults[2].results.hits.length || 0

        },
        // Detect if the search query is a command
        isCommandMode: (state) => {
            if (state.forceCommandMode) return true
            return state.searchQuery.startsWith('!') || state.searchQuery.startsWith('?') || state.searchQuery.startsWith('>') || state.searchQuery.startsWith('#') || false
        }
    },

    actions: {
        openSearchOverlay() {
            this.showGlobalSearchOverlay = true
        },
        closeSearchOverlay() {
            this.showGlobalSearchOverlay = false
        },
        clearResults() {
            this.searchQueryPending = false
            this.searchRawResults = []
            this.searchResultCustomers = []
            this.searchResultOrders = []
            this.searchResultEvents = []
            this.forceCommandMode = false
        },
        getItemRoute(item) {
            // Detect the route to push the user to when an item is clicked

            console.log(`Getting route for item: `, item)
            // If the item has a route param we can send the user strait to that
            if (item.route) return item.route

            // If the item has a path it is likely an Algolia result..
            if (item.path) {
                // If the item has a path, we can detect the type and route to the correct page
                const pathSegments = item.path.split('/')
                const tenant = pathSegments[1]
                const type = pathSegments[2]
                const id = pathSegments[3]
                return { path: `/app/tenant-admin/${type}/${id}`, query: { tenant, searchTerm: `${this.searchQuery}` } }
            }

            // Otherwise, detect from the object type
            if (item.customerData) return { path: `/app/tenant-admin/customers/${item.id}`, query: { tenant: item.meta?.tenant, searchTerm: `${this.searchQuery}` } }

            return null
        },
        updateSearchQuery(query: string) {
            this.searchQuery = query
            this.searchQueryPending = true
        },
        async fetchSearch(query) {

            console.log(`Fetching search result for: `, { query })
            try {
                const res = await $fetch('/api/search', { method: 'post', body: { ...query } })
                console.log(`Got res from ${query.index}: `, res)
                if (!res.results) return null
                return res
            } catch (error) {
                console.error(`Error fetching search results: `, error)

            }
        },
        async runSearch() {
            // Do nothing if search is loading
            if (this.isLoading) return


            // Reset results if field is empty
            if (this.searchQuery === '') {
                console.log(`Resetting search results`)
                this.isLoading = false
                return this.clearResults()
            }

            // Do not run search if first character is a command mode one.
            if (this.searchQuery.startsWith('>') || this.searchQuery.startsWith('?') || this.searchQuery.startsWith('#') || this.searchQuery.startsWith('!')) {
                return this.commandMode = true
            } else {
                //  Otherwise reset the commandMode state
                this.commandMode = false
            }

            // Do not run search if there are less than 3 characters in the field
            if (this.searchQuery.length < 3) {
                return this.queryTooShort = true
            } else {
                this.queryTooShort = false
            }



            // Run query
            this.isLoading = true

            // Run HTTP requests

            // const result = await $algolia.search(this.allIndexesQueries)
            console.log(`Fetching results...`)
            const promises = await this.allIndexesQueries.map((query) => {
                return this.fetchSearch(query)
            })

            const result = await Promise.all(promises)

            console.log(`Results are: `, { result })

            // Update state with the results
            this.searchRawResults = result

            this.searchResultCustomers = useFind(result, ['index', 'all_customers'])?.results?.hits || []
            this.searchResultOrders = useFind(result, ['index', 'all_orders'])?.results?.hits || []
            // this.searchResultEvents = find(result.results, ['index', 'events'])?.hits

            this.isLoading = false
            this.searchQueryPending = false
        },

    },
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useSearchStore, import.meta.hot))
}
